import axios from "axios";
import React, { useRef, useState } from "react";
import SectionHeading from "./SectionHeading";
const ContactForm1 = () => {
  const name = useRef();
  const lastName = useRef();
  const company = useRef();
  const phone = useRef();
  const email = useRef();
  const message = useRef();
  const [showMsg, setShowMsg] = useState("");
  const clearInputs = () => {
    name.current.value = "";
    lastName.current.value = "";
    company.current.value = "";
    phone.current.value = "";
    email.current.value = "";
    message.current.value = "";
  };
  const sendContactForm = async () => {
    try {
      const response = await axios.post(
        "https://admin.hiri.ai/items/contacts",
        {
          first_name: name.current.value,
          last_name: lastName.current.value,
          company: company.current.value,
          phone: phone.current.value,
          email: email.current.value,
          message: message.current.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer Vr3HjTItjSdZtI3kPxcuR9VmA1skYV7E",
          },
        }
      );
      console.log(response);
      clearInputs();
      setShowMsg("The form has been successfully submitted.");
    } catch (error) {
      console.log(error);
      setShowMsg("");
      throw error;
    }
  };
  return (
    <div className="contact-form drop-shadow-2">
      <div className="contact-form-wrapper">
        <SectionHeading
          icon="las la-envelope"
          heading="contact"
          subHeading="Write message"
          additionalClasses="section-heading-2 center"
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendContactForm();
          }}
        >
          <div className="form-floating">
            <input
              required
              className="input form-control"
              id="name-field"
              type="text"
              placeholder="First Name"
              ref={name}
              onChange={(e) => {
                name.current.value = e.target.value;
              }}
            />
            <label htmlFor="name-field">First Name</label>
          </div>
          <div className="form-floating">
            <input
              required
              className="input form-control"
              id="last-name-field"
              type="text"
              placeholder="Last Name"
              ref={lastName}
              onChange={(e) => {
                lastName.current.value = e.target.value;
              }}
            />
            <label htmlFor="last-name-field">Last Name</label>
          </div>
          <div className="form-floating">
            <input
              required
              className="input form-control"
              id="company-field"
              type="text"
              placeholder="Company Name"
              ref={company}
              onChange={(e) => {
                company.current.value = e.target.value;
              }}
            />
            <label htmlFor="company-field">Company</label>
          </div>
          <div className="form-floating">
            <input
              required
              className="input form-control"
              id="phone-field"
              type="number"
              placeholder="Phone number"
              ref={phone}
              onChange={(e) => {
                phone.current.value = e.target.value;
              }}
            />
            <label htmlFor="phone-field">Phone</label>
          </div>
          <div className="form-floating">
            <input
              required
              className="input form-control"
              id="email-field"
              type="email"
              placeholder="Email"
              ref={email}
              onChange={(e) => {
                email.current.value = e.target.value;
              }}
            />
            <label htmlFor="email-field">Email Address</label>
          </div>
          <div className="form-floating">
            <input
              required
              className="input form-control"
              id="message-field"
              type={"text"}
              placeholder="Message"
              ref={message}
              onChange={(e) => {
                message.current.value = e.target.value;
              }}
            />
            <label htmlFor="message-field">Message</label>
          </div>
          <button type="submit" className="button button-3">
            <span className="button-inner">
              <span className="button-content">
                <span className="text">Submit</span>
              </span>
            </span>
          </button>
          {showMsg !== "" && <div className="m-4 text-center">{showMsg}</div>}
        </form>
      </div>
    </div>
  );
};

export default ContactForm1;
